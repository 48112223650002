import Heading from 'components/global/Heading';
import Layout from 'components/global/Layout';
import PasswordInput from 'components/global/PasswordInput';
import Axios from 'config/api';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';

const initState = {
    oldPassword : '' ,
    newPassword : '' ,
    passwordConfirm : ''
}

const UpdatePassword = () => {
    const [data , setData] = useState(initState);
    const [loading , setLoading] = useState(false);

    const { user } = useSelector(state => state.auth);

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const body = {...data};
            const { data : { data : { message } } } = await Axios.put('/user/update-password' , body , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
            setData(initState)
            toast.success(message);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log('Update password error' , err);
            toastError(err);
        }
    }

    return (
        <Layout>
            <Heading title='Update Password' showIcon={false} />
            <form 
            className='shadow-bg p-4 mt-4'
            onSubmit={submitHandler}
            >
                <div className=' flex flex-col gap-4'>
                    <div>
                        <PasswordInput
                        label='Old Password'
                        placeholder='Ex : *********'
                        name='oldPassword'
                        data={data}
                        setData={setData}
                        required
                        />
                    </div>
                    <div className='flex items-center gap-4 sm:flex-row flex-col'>
                        <PasswordInput
                        label='New Password'
                        placeholder='Ex : *********'
                        name='newPassword'
                        data={data}
                        setData={setData}
                        required
                        />
                        <PasswordInput
                        label='Confirm Password'
                        name='passwordConfirm'
                        placeholder='Ex : **********'
                        data={data}
                        setData={setData}
                        required
                        />
                    </div>
                    
                    <div className='mt-4 flex items-end justify-end'>
                        <button 
                        className="btn-primary py-2 px-12"
                        type='submit'
                        disabled={loading}
                        >
                            {
                                loading
                                ?
                                    <ClipLoader size={20} color='white' />
                                : 
                                    'Save'
                            }
                        </button>
                    </div>
                </div>
            </form>
        </Layout>
    )
}

export default UpdatePassword