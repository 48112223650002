import { memo } from 'react'

const PhoneInput = ({ 
     name = '' , setData , data , setIsValid , label , ...props
}) => {

    const handleChange = (e) => {
        const { name , value } = e.target;
        setData(prev => ({...prev , [name] : value }))
        setIsValid(validateInput(value));
    }

    const validateInput = (value) => {
        const inputValue = value || data[name];  
        const phoneRegex = /^[6-9]\d{9}$/;
    
        return phoneRegex.test(inputValue);
    };



    return (
        <div className='flex flex-col gap-1.5 flex-1 w-full'>
            {
                label && 
                <label className='font-semibold text-gray-600 '>
                    {label}
                </label>
            }
            <input 
            type={'number'}
            placeholder={'Please enter your phone no'}
            className={`input w-full ${validateInput() ? '' : data[name]?.length > 0 ? 'input-error' : ''}`}
            name={name}
            value={data[name]}
            onChange={handleChange}
            {...props}
            />
            {data[name]?.length > 0 && !validateInput() && (
                <p className='text-red-500 text-xs'>{'Please enter a valid phone number'}</p>
            )}
        </div>
    )
}

export default memo(PhoneInput);