import BackBtn from 'components/global/BackBtn';
import Heading from 'components/global/Heading';
import ItemNotFound from 'components/global/ItemNotFound';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import Search from 'components/global/Search';
import UsersTable from 'components/userManagement/UsersTable';
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { getAllUsers, user_deleteMultipleUsers } from 'redux/actions/userActions';
import { setCurrentPage, setDocsCount, setKeyword, setPages, setUsers } from 'redux/reducers/userReducer';
import fetcher from 'utils/fetcher';

const Users = () => {
    const { user } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [range , setRange] = useState('');
    const [selectAll , setSelectAll] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const { currentPage , users , docsCount , keyword , deleteLoading} = useSelector(state => state.user);

    const queryKey = ['fetch-users' , range , currentPage , keyword]
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/user/all?range=${range}&keyword=${keyword}&page=${currentPage}` , user);
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { docs , docCount , page , pages} } } = data;
            dispatch(setUsers(docs));
            dispatch(setCurrentPage(page));
            dispatch(setPages(pages));
            dispatch(setDocsCount(docCount));
        }
    } , [data])

    const deleteUsersHandler = () => {
        if(window.confirm('Are you sure? You want to delete these users?')) {
            dispatch(user_deleteMultipleUsers({ userIds : selectedUsers } , setSelectAll , setSelectedUsers));
        }
    }


    const searchFetcher = (value) => {
        dispatch(setKeyword(value));
    }


    return (
        <Layout>
            <div className="flex items-center justify-between">
                <div className='flex items-center gap-2'>
                    <Heading title='User Management' showIcon={false} />
                    <div className='bg-gray-100 flex items-center justify-center py-1 rounded-md px-3'>
                        {docsCount || 0}
                    </div>
                </div>
                <BackBtn />
            </div>
            <div className='flex items-center justify-between mt-6'>
                <div>
                    <Search 
                    fetcher={searchFetcher} 
                    />
                </div>
                <div className='flex items-center gap-4'>
                    <Link to='/user-management/add-new'>
                        <button className="btn-primary py-2 px-12">ADD</button>
                    </Link>
                    
                </div>
            </div>
            <div className='my-6'>
                <div className='shadow-bg overflow-x-auto rounded-lg'>
                    <div className='py-4 flex items-center justify-between px-4'>
                        <div>
                        {
                            selectedUsers.length > 1 && 
                                <button 
                                className="border border-red-500 text-red-600 hover:bg-red-500 duration-300 ease-in-out rounded-md hover:text-pure py-2 px-8 flex items-center gap-2"
                                onClick={deleteUsersHandler}
                                disabled={deleteLoading}
                                >
                                    {
                                        deleteLoading
                                        ? 
                                            <ClipLoader size={15}  />
                                        : 
                                            <>
                                                <i className="uil uil-trash"></i>
                                                Delete Selected
                                            </>
                                    }
                                </button>
                        }
                        </div>
                        <div className=' flex items-end justify-end px-4'>
                            <select 
                            className='select-box'
                            onChange={e => setRange(e.target.value)}
                            value={range}
                            >
                                <option value="all">All</option>
                                <option value="today">Today</option>
                                <option value="week">Week</option>
                                <option value="month">Month</option>
                            </select>
                        </div>
                    </div>
                    {
                        isLoading 
                        ?
                            <Loader />
                        :
                        users?.length > 0 
                        ? 
                            <UsersTable 
                            setRange={setRange} 
                            selectedUsers={selectedUsers}
                            setSelectedUsers={setSelectedUsers}
                            selectAll={selectAll}
                            setSelectAll={setSelectAll}
                            />
                        : 
                            <ItemNotFound />
                    }
                </div>
            </div>
        </Layout>
    )
}

export default Users;