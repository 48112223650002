import React, { useState, useEffect, memo, useMemo } from 'react';
import { GoogleMap, InfoWindow , useLoadScript , MarkerF } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';
import { setShowBookingsMap } from 'redux/reducers/VanBookingsReducer';
import { useNavigate } from 'react-router-dom';

const mapContainerStyle = {
    width: '100%',
    height: '100vh',
    position : 'absolute' ,
    top : 0 ,
    left : 0 , 
    zIndex : 999
};

function PreBookingsMap({ bookings }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedMarker , setSelectedMarker] = useState(null);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    });
    const center = useMemo(() => ({ lat: 26.8467, lng: 80.9462 }), []);

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => document.body.style.overflow = 'auto';
    } , [])

    return (
        <div>
            {
                !isLoaded
                ? 
                    <h3>Loading...</h3>
                : 
                    <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        center={center}
                        zoom={10}
                    >
                        {      
                            bookings?.length > 0 
                            ? 
                                bookings?.map(booking => (
                                    <div key={booking?._id}>
                                        <MarkerF
                                        position={{ 
                                            lat: booking?.customerLocation?.lat, 
                                            lng: booking?.customerLocation?.lng 
                                        }}
                                        // onClick={() => setSelectedMarker(booking)}
                                        onClick={() => {
                                            dispatch(setShowBookingsMap(false));
                                            navigate(`/order-management/pre-bookings/booking-details/${booking?._id}`)
                                        }}
                                        />
                                        {/* {selectedMarker?._id === booking?._id && (
                                        <InfoWindow
                                            position={{
                                                lat: booking?.customerLocation?.lat,
                                                lng: booking?.customerLocation?.lng
                                            }}
                                            onCloseClick={() => setSelectedMarker(null)}
                                            className='z-[50]'
                                        >
                                            <div>
                                                <h3>{booking?.username}</h3>
                                            </div>
                                        </InfoWindow>
                                        )} */}
                                    </div>
                                ))
                            : 
                                <div></div>
                        }
                        <div className='absolute top-4 left-1/2 -translate-x-1/2'>
                            <button 
                            className="btn-primary rounded-full w-[40px] h-[40px] flex items-center justify-center shadow-lg cursor-pointer text-xl font-bold text-white"
                            title='close map'
                            onClick={() => {
                                dispatch(setShowBookingsMap(false))
                                // setBookings([]);
                            }}
                            >
                                <i className="uil uil-times"></i>
                            </button>
                        </div>
                    </GoogleMap>
            }
        </div>
    );
}

export default memo(PreBookingsMap);
