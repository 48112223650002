import { createSlice } from "@reduxjs/toolkit";

const vanBookingSlice = createSlice({
    name : 'vanBooking' ,
    initialState : {
        bookings : [] ,
        bookingDetails : null ,
        loading : false , 
        createLoading : false , 
        updateLoading : false , 
        deleteLoading : false , 
        currentPage : 1 ,
        pages : 1 , 
        docsCount : 0 ,
        status : '' ,
        duration : '' ,
        showAssignBookingPopup : false ,
        showBookingsMap : false 
    } , 
    reducers : {
        setDocs (state , action) {
            state.bookings = action.payload
        } ,
        setDocDetails (state , action) {
            state.bookingDetails = action.payload
        } ,
        addDoc (state , action) {
            state.bookings = state.bookings.unshift(action.payload);
        } ,
        updateDoc (state , action) {
            const index = state.bookings.findIndex(i => i._id === action.payload._id);
            state.bookings[index] = action.payload;
            state.bookingDetails = action.payload;
        } ,
        removeDoc (state , action) {
            state.bookings = state.bookings.filter(i => i._id !== action.payload);
        } ,
        setShowAssignBookingPopup (state , action) {
            state.showAssignBookingPopup = action.payload
        } , 
        setShowBookingsMap (state , action) {
            state.showBookingsMap = action.payload
        } , 
        setLoading (state , action) {
            state.loading = action.payload;
        } ,
        setUpdateLoading (state , action ) {
            state.updateLoading = action.payload
        } ,
        setCreateLoading (state , action ) {
            state.createLoading = action.payload
        } ,
        setDeleteLoading (state , action ) {
            state.deleteLoading = action.payload
        } ,
        setCurrentPage (state , action) {
            state.currentPage = action.payload;
        } ,
        setPages (state , action) {
            state.pages = action.payload;
        } ,
        setDocsCount (state , action) {
            state.docsCount = action.payload;
        } , 
        setDuration (state , action) {
            state.duration = action.payload;
        } ,
        setStatus (state , action) {
            state.status = action.payload;
        }
    }
});

export const { 
    setDocs , setDocDetails , setLoading , setCreateLoading , setUpdateLoading , setDeleteLoading , setCurrentPage , setPages , updateDoc , removeDoc , setDocsCount , addDoc , setStatus , setDuration , setShowAssignBookingPopup , setShowBookingsMap
} = vanBookingSlice.actions;

export default vanBookingSlice.reducer;