import BackBtn from 'components/global/BackBtn'
import Heading from 'components/global/Heading'
import Input from 'components/global/Input'
import Layout from 'components/global/Layout';
import React, { useEffect } from 'react';
import { useState } from 'react';
import SelectBox from 'components/global/SelectBox';
import MultiFileInput from 'components/global/MultiFileInput';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCategories } from 'redux/actions/categoryActions';
import { ClipLoader } from 'react-spinners';
import { getMainSubCategories } from 'redux/actions/subCategoryActions';
import { createProduct } from 'redux/actions/productActions';
import NameInput from 'components/global/NameInput';

const AddInventory = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [inventoryData , setInventoryData] = useState({
        name : '' ,
        purchasePrice : '' ,
        salesPrice : '' ,
        MRP : '' ,
        stock : '',
        description : '' ,
        itemCode : '' ,
    });
    const [images , setImages] = useState([]);
    const [mainCategory , setMainCategory] = useState('')
    const [subCategory , setSubCategory] = useState('');

    const { loading : catLoading , categories } = useSelector(state => state.category);
    const { loading : subCatLoading , subCategories } = useSelector(state => state.subCategory);

    const { createLoading } = useSelector(state => state.inventory);

    useEffect(() => {
        dispatch(getCategories());
    }, []);

    useEffect(() => {
        if(mainCategory){
            dispatch(getMainSubCategories(mainCategory))
        }
    }, [mainCategory])

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {...inventoryData };
        if(mainCategory) data.mainCategory = mainCategory;
        if(subCategory) data.subCategory = subCategory;
        if(images?.length > 0 ) data.images = images;
        dispatch(createProduct(data , navigate));
    }

    return (
        <Layout>
            <div>
                <div className='flex items-center justify-between gap-4'>
                    <div>
                        <Heading title='Add Inventory' />
                    </div>
                    <div>
                        <BackBtn />
                    </div>
                </div>
                <form 
                className='shadow-bg p-4 mt-4 flex flex-col gap-4'
                onSubmit={handleSubmit}
                >
                    <div className='flex sm:flex-row flex-col gap-6'>
                        <NameInput 
                        label='Name*'
                        placeholder='Ex: Apple'
                        data={inventoryData}
                        setData={setInventoryData}
                        name='name'
                        required
                        />
                        <NameInput 
                        type='number'
                        label='Stock*'
                        placeholder='Available Stock'
                        data={inventoryData}
                        setData={setInventoryData}
                        name='stock'
                        required
                        />
                        
                    </div>
                    <div className='flex sm:flex-row flex-col gap-6'>
                        <NameInput 
                        type='number'
                        label='Purchase Price*'
                        placeholder='Ex: 50'
                        data={inventoryData}
                        setData={setInventoryData}
                        name='purchasePrice'
                        required
                        />
                        <NameInput 
                        type='number'
                        label='Sales Price*'
                        placeholder='Ex : 60'
                        data={inventoryData}
                        setData={setInventoryData}
                        name='salesPrice'
                        required
                        />
                    </div>
                    <div className='flex sm:flex-row flex-col gap-6'>
                        <div className='flex-1'>
                        {
                            catLoading 
                            ? 
                                <ClipLoader size={15} />
                            : 
                            <SelectBox
                            options={categories?.map(item => (
                                { value : item?._id , label : item?.name }
                            ))}
                            label='Category'
                            setValue={setMainCategory}
                            />
                        }
                        </div>
                        <div className='flex-1'>
                        {
                            subCatLoading 
                            ? 
                                <ClipLoader size={15} />
                            : 
                            <SelectBox
                            options={subCategories?.map(item => (
                                { value : item?._id , label : item?.name }
                            ))}
                            label='Sub Category'
                            setValue={setSubCategory}
                            />
                        }
                        </div>
                    </div>
                    <div className="flex sm:flex-row flex-col gap-6">
                        <NameInput 
                        type='number'
                        label='MRP*'
                        placeholder='Ex: 100'
                        data={inventoryData}
                        setData={setInventoryData}
                        name='MRP'
                        required
                        />
                        <NameInput 
                        label='Item Code*'
                        placeholder='Ex: 234234234'
                        data={inventoryData}
                        setData={setInventoryData}
                        name='itemCode'
                        required
                        />
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label className='font-semibold text-grayText'>
                            Description
                        </label>
                        <textarea
                        placeholder='Description...' 
                        className='input h-[100px] resize-none' 
                        value={inventoryData?.description}
                        onChange={e => setInventoryData(prev => ({...prev , description : e.target.value }))}
                        />
                    </div>
                    <div>
                        <MultiFileInput 
                        images={images}
                        setImages={setImages}
                        />
                    </div>
                    <div className='mt-4'>
                        <button 
                        type="submit" 
                        className='btn-primary py-3 px-12'
                        disabled={createLoading }
                        >
                            {
                                createLoading 
                                ? 
                                    <ClipLoader size={20} color='white' />
                                : 
                                    'Submit'
                            }
                        </button>
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default AddInventory;