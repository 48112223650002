import OrderStatus from 'components/global/OrderStatus';
import Pagination from 'components/global/pagination';
import moment from 'moment';
import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setPages, setShowAssignBookingPopup, setShowBookingsMap } from 'redux/reducers/VanBookingsReducer';
import useClickOutside from 'utils/clickOutside';
import PreBookingsMap from './PreBookingsMap';
import AssignBookingPopup from './AssignBookingPopup';

const PreBookingsTable = () => {
    const dispatch = useDispatch();
    const dropMenuRef = useRef(null);
    const [showDropMenu , setShowDropMenu] = useState(false);
    const [selectedMenuIndex , setSelectedMenuIndex]  = useState(0);
    const [selectedBooking , setSelectedBooking] = useState('');


    const { bookings , currentPage , pages , showBookingsMap , showAssignBookingPopup } = useSelector(state => state.vanBooking);

    useClickOutside(dropMenuRef , () =>  setShowDropMenu(false));

    const viewMapHandler = (booking) => {
        setSelectedBooking(booking);
        dispatch(setShowBookingsMap(true));
    }

    return (
        <div className=" shadow-bg overflow-x-auto rounded-lg">
            <table className="w-full table-auto overflow-x-auto ">
                <thead className="bg-lightSlate border-b text-sm">
                    <tr className='bg-secondary text-white'>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            BOOKING ID
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            CUSTOMER
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            VAN
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            DATE
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            BOOKING AMOUNT
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            BOOKING STATUS
                        </th>   
                        <th scope="col" className=" font-medium px-6 py-4 text-center">
                            ACTIONS
                        </th>
                    </tr>
                </thead>
                <tbody className='text-sm'>
                   {
                        bookings?.map((item , i) => (
                            <tr
                            key={item?._id} 
                            className="bg-white border-b transition duration-300 ease-in-out"
                            >
                            <td className=" text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {item?._id?.slice(-5)}   
                            </td>
                            <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
                                {item?.user?.firstName + ' ' + item?.user?.lastName}
                            </td>
                            <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
                                {
                                    item?.driver 
                                    ? 
                                        <span>
                                            {item?.driver?.firstName + " " + item?.driver?.lastName}
                                        </span>
                                    : 
                                        <span className='text-red-500'>Not Assigned Yet.</span>
                                }
                            </td>
                            <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
                                {moment(item?.date).format('DD MMM YYYY')}
                            </td>
                            <td className=" text-gray-900  px-6 py-4 whitespace-nowrap text-center">
                                {item?.bookingAmount || 0}
                            </td>
                            <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
                                <OrderStatus 
                                status={item?.status}
                                />
                            </td>
                            <td className="text-gray-900  px-6 py-4 whitespace-nowrap">
                                <div className='flex items-end justify-center relative' 
                                >  
                                    <div className='bg-gray-500 py-1.5 px-4 flex items-center rounded-md text-pure gap-2 text-lg w-fit cursor-pointer'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowDropMenu(prev => !prev);
                                        setSelectedMenuIndex(i);
                                    }}
                                    >
                                        <div><i className="uil uil-setting"></i></div>
                                        <div><i className="uil uil-angle-down"></i></div>
                                    </div>
                                {/* DROP MENU */}
                                {   
                                        showDropMenu && selectedMenuIndex === i &&
                                        <div className='absolute top-10  bg-pure shadow-lg w-[120px] h-auto rounded-lg z-[50] border flex flex-col'
                                        ref={dropMenuRef}
                                        >
                                            <Link 
                                            to={`/order-management/pre-bookings/booking-details/${item?._id}`} 
                                            className='py-3 font-medium hover:bg-gray-100 px-4 cursor-pointer flex items-center gap-1'>
                                                <span>View</span>
                                            </Link>
                                            <button
                                            className='py-3 font-medium hover:bg-gray-100 px-4 cursor-pointer flex items-center gap-1'
                                            onClick={() => viewMapHandler(item)}
                                            >
                                                <span>View Map</span>
                                            </button>
                                            <button
                                            className='py-3 font-medium hover:bg-gray-100 px-4 cursor-pointer flex items-center gap-1'
                                            onClick={() => {
                                                setSelectedBooking(item);
                                                dispatch(setShowAssignBookingPopup(true));
                                            }}
                                            >
                                                <span>Assign Booking</span>
                                            </button>
                                        </div>
                                    }
                                </div>
                            </td>
                        </tr>
                        ))
                   }
                
                </tbody>
            </table>
            {
                <Pagination 
                currentPage={currentPage}
                pageCount={pages}
                setPage={setPages}
                />
            }

            {
                showBookingsMap && selectedBooking && (
                    <PreBookingsMap 
                    bookings={[selectedBooking]}
                    />
                )
            }

            {
                showAssignBookingPopup &&
                <AssignBookingPopup 
                booking={selectedBooking}
                />
            }
        </div>
    )
}

export default PreBookingsTable;