import BackBtn from 'components/global/BackBtn'
import ItemNotFound from 'components/global/ItemNotFound';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import OrdersTable from 'components/orderManagement/OrdersTable';
import { baseURL } from 'config/api';
import users from 'data/users';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setCurrentPage, setDocs, setDocsCount, setPages } from 'redux/reducers/orderReducer';
import fetcher from 'utils/fetcher';

const UserDetails = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { user } = useSelector(state => state.auth);
    const [userDetails , setUserDetails] = useState('');
    const { orders  } = useSelector(state => state.order)

    const queryKey = ['fetch-user-details' , id]
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/order/user/${id}` ,user)
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { docs , docsCount , page, pages , user : userDetails } } } = data;
            dispatch(setDocs(docs));
            dispatch(setDocsCount(docsCount));
            dispatch(setPages(pages));
            dispatch(setCurrentPage(page));
            setUserDetails(userDetails);
        }
    }, [data]);
    console.log({ data })

    return (
        <Layout>
            <div>
                <BackBtn />
                {
                    isLoading 
                    ? 
                        <Loader />
                    : 
                        <div>
                            <div className='shadow-bg py-4 mt-4 flex flex-col items-center justify-center gap-4'>
                                <img 
                                src={baseURL + '/users/' + userDetails?.image} 
                                alt={userDetails?.firstName + ' ' + userDetails?.lastName} 
                                className='w-[100px] h-[100px] rounded-full object-cover'
                                />
                                <div className='text-center text-grayText'>
                                    <h4>{userDetails?.firstName + ' ' + userDetails?.lastName}</h4>
                                    <p>{userDetails?.email}</p>
                                </div>
                            </div>
                            <div className='mt-6'>
                                <h3 className='heading-sm'>Orders</h3>
                                {
                                    orders?.length > 0 
                                    ? 
                                        <OrdersTable  />
                                    :
                                        <ItemNotFound message='No order found.' />
                                }
                            </div>
                        </div>

                }
            </div>
        </Layout>
    )
}

export default UserDetails