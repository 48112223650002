import BackBtn from 'components/global/BackBtn';
import Heading from 'components/global/Heading';
import ItemNotFound from 'components/global/ItemNotFound';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import Search from 'components/global/Search';
import PreBookingFilters from 'components/orderManagement/PreBookingFilters';
import PreBookingsMap from 'components/orderManagement/PreBookingsMap';
import PreBookingsTable from 'components/orderManagement/PreBookingsTable';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllVanBookings } from 'redux/actions/vanBookingActions';
import { setShowBookingsMap } from 'redux/reducers/VanBookingsReducer';

const PreBookings = () => {
    const dispatch = useDispatch();
    const { loading , bookings , currentPage , status , duration , showBookingsMap } = useSelector(state => state.vanBooking);

    useEffect(() => {
        dispatch(getAllVanBookings())
    }, [currentPage , status , duration]);

    return (
        <Layout>
            <div className='pb-12'>
                <div className='flex items-center justify-between gap-4'>
                    <BackBtn />
                    <button 
                    className="btn-primary py-2 px-6"
                    onClick={() => {
                        dispatch(setShowBookingsMap(true));
                    }}
                    >
                        Show Bookings Map
                    </button>
                </div>
                {
                    showBookingsMap 
                    ? 
                        <div>
                            <PreBookingsMap bookings={bookings} />
                        </div>
                    : 
                        ''
                }
                <div className='flex flex-col gap-4 mt-4'>
                    <div>
                        <Heading title='All Bookings' showIcon={false} />
                    </div>
                    <div>
                        <PreBookingFilters />
                    </div>
                </div>
                <div className='mt-6'>
                    {
                        loading 
                        ? 
                            <Loader />
                        : 
                        bookings?.length > 0 
                        ? 
                            <PreBookingsTable />
                        : 
                            <ItemNotFound />
                    }
                </div>
            </div>
        </Layout>
    )
}

export default PreBookings