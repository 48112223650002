import Cards from 'components/dashboard/Cards';
import OrdersList from 'components/dashboard/OrdersList';
import TopCustomers from 'components/dashboard/TopCustomers';
import TopSellings from 'components/dashboard/TopSellings';
import TotalRevenue from 'components/dashboard/TotalRevenue';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import fetcher from 'utils/fetcher';

const Dashboard = () => {
    const { user } = useSelector(state => state.auth);
    const [stats , setStats] = useState('');
    
    const { isLoading , data } = useQuery('fetch-dashboard-details' , () => {
        return fetcher('/admin/dashboard-details' , user);
    });

    useEffect(() => {
        if(data) {
            setStats(data?.data?.data);
        }
    } , [data]);

    console.log({ stats })
   
    return (
        <Layout>
            {
                isLoading 
                ? 
                    <Loader />
                : 
                <div className='flex flex-col gap-8'>
                    <div>
                        <Cards 
                        totalOrdersCount={stats?.totalOrdersCount}
                        totalProductsCount={stats?.totalProductsCount}
                        totalCategoriesCount={stats?.totalCategoriesCount}
                        todayOrdersCount={stats?.todayOrdersCount}
                        totalEarning={stats?.totalEarning}
                        />
                    </div>
                    {/* <div>
                        <TotalRevenue />
                    </div> */}
                    {/* <div>
                        <OrdersList />
                    </div> */}
                    <div>
                        <TopSellings 
                        topProducts={stats?.topProducts}
                        />
                    </div>
                    <div>
                        <TopCustomers 
                        topCustomers={stats?.topCustomers}
                        />
                    </div>
                </div>

            }
        </Layout>
    )
}

export default Dashboard