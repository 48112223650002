import Sidebar from "components/global/sidebar";
import { BrowserRouter as Router , Routes , Route, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { useState } from "react";
import Dashboard from "pages/dashboard";
import Users from "pages/userManagement";
import UserDetails from "pages/userManagement/UserDetails";
import AddUser from "pages/userManagement/AddUser";
import EditUser from "pages/userManagement/EditUser";
import Orders from "pages/orderManagement";
import PreBookings from "pages/orderManagement/PreBookings";
import AcceptedOrders from "pages/orderManagement/AcceptedOrder";
import Inventories from "pages/inventoryManagement";
import AddInventory from "pages/inventoryManagement/AddInventory";
import Drivers from "pages/driverManagement";
import DriverDetails from "pages/driverManagement/DriverDetails";
import AddDriver from "pages/driverManagement/AddDriver";
import EditDriver from "pages/driverManagement/EditDriver";
import Banners from "pages/banners";
import AddNewBanner from "pages/banners/AddNewBanner";
import EditBanner from "pages/banners/EditBanner";
import EditInventory from "pages/inventoryManagement/EditInventory";
import MainCategory from "pages/categoryManagement";
import SubCategory from "pages/categoryManagement/SubCategory";
import AddSubCategory from "pages/categoryManagement/AddSubCategory";
import AnalyticsAndReporting from "pages/analyticsAndReporting";
import Coupons from "pages/coupons";
import AddCoupon from "pages/coupons/AddCoupon";
import Login from "pages/auth/Login";
import AddCategory from "pages/categoryManagement/AddCategory";
import EditCategory from "pages/categoryManagement/EditCategory";
import EditSubCategory from "pages/categoryManagement/EditSubCategory";
import EditCoupon from "pages/coupons/EditCoupon";
import OrderDetails from "components/orderManagement/OrderDetails";
import Settings from "pages/settings";
import PreBookingDetails from "pages/orderManagement/PreBookingDetails";
import Notifications from "pages/notifications";
import EditNotification from "pages/notifications/EditNotification";
import SendNotification from "pages/notifications/SendNotification";
import ProtectedRoute from "ProtectedRoute";
import { useApi } from "config/api";
import BulkUpload from "pages/inventoryManagement/BulkUpload";
import UpdatePassword from "pages/updatePassword";
import Profile from "pages/profile";
import ForgotPassword from "pages/auth/ForgotPassword";
import VerifyForgotOtp from "pages/auth/VerifyForgotOtp";
import ResetPassword from "pages/auth/ResetPassword";

function App() {
    const api = useApi();
    const location = useLocation();
    const [isLoginPage , setIsLoginPage] = useState(false);

    const isAuthPage = () => {
        if(location.pathname === '/login' || location.pathname === '/verify-forgot-otp' || location.pathname === '/verify-login-otp' || location.pathname === '/forgot-password' || location.pathname === '/reset-password') {
            return true;
        }
        return false;
    }

    return (
        <div className="space">
            <ToastContainer 
                style={{fontSize: 15}}
                position="top-center"
                autoClose={3000}
                closeOnClick
                pauseOnHover
            />
            {!isAuthPage() && <Sidebar />}
            <Routes>
                <Route 
                path='/' 
                element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>} 
                />
                <Route 
                path='/login' 
                element={
                    <Login />
                } 
                />
                <Route 
                path='/forgot-password' 
                element={
                    <ForgotPassword />
                } 
                />
                <Route 
                path='/verify-forgot-otp' 
                element={
                    <VerifyForgotOtp />
                } 
                />
                 <Route 
                path='/reset-password' 
                element={
                    <ResetPassword />
                } 
                />
                <Route 
                path='/user-management/users' 
                element={
                    <ProtectedRoute>
                        <Users />
                    </ProtectedRoute>
                } />
                <Route 
                path='/update-password' 
                element={
                    <ProtectedRoute>
                        <UpdatePassword />
                    </ProtectedRoute>
                } />
                <Route 
                path='/profile' 
                element={
                    <ProtectedRoute>
                        <Profile />
                    </ProtectedRoute>
                } />
                <Route 
                path='/user-management/users/:id' 
                element={
                    <ProtectedRoute>
                        <UserDetails />
                    </ProtectedRoute>
                } 
                />
                <Route 
                path='/user-management/add-new'
                element={
                    <ProtectedRoute>
                        <AddUser />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/user-management/edit-user/:id'
                element={
                    <ProtectedRoute>
                        <EditUser />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/order-management/orders'
                element={
                    <ProtectedRoute>
                        <Orders />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/order-management/order-details/:id'
                element={
                    <ProtectedRoute>
                        <OrderDetails />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/order-management/pre-bookings'
                element={
                    <ProtectedRoute>
                        <PreBookings />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/order-management/pre-bookings/booking-details/:id'
                element={
                    <ProtectedRoute>
                        <PreBookingDetails />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/order-management/accepted-orders'
                element={
                    <ProtectedRoute>
                        <AcceptedOrders />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/inventory-management/inventories'
                element={
                    <ProtectedRoute>
                        <Inventories />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/inventory-management/add-new'
                element={
                    <ProtectedRoute>
                        <AddInventory />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/inventory-management/bulk-upload'
                element={
                    <ProtectedRoute>
                        <BulkUpload  />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/inventory-management/edit-inventory/:id'
                element={
                    <ProtectedRoute>
                        <EditInventory />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/driver-management/drivers'
                element={
                    <ProtectedRoute>
                        <Drivers />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/driver-management/drivers/:id' 
                element={
                    <ProtectedRoute>
                        <DriverDetails />
                    </ProtectedRoute>
                } 
                />
                <Route 
                path='/driver-management/add-new'
                element={
                    <ProtectedRoute>
                        <AddDriver />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/driver-management/edit-driver/:id'
                element={
                    <ProtectedRoute>
                        <EditDriver />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/banners'
                element={
                    <ProtectedRoute>
                        <Banners />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/banners/add-new'
                element={
                    <ProtectedRoute>
                        <AddNewBanner />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/banners/edit-banner/:id'
                element={
                    <ProtectedRoute>
                        <EditBanner />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/category-management/main-category'
                element={
                    <ProtectedRoute>
                        <MainCategory />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/category-management/main-category/add'
                element={
                    <ProtectedRoute>
                        <AddCategory />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/category-management/main-category/edit/:id'
                element={
                    <ProtectedRoute>
                        <EditCategory />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/category-management/sub-category'
                element={
                    <ProtectedRoute>
                        <SubCategory />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/category-management/sub-category/add'
                element={
                    <ProtectedRoute>
                        <AddSubCategory />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/category-management/sub-category/edit/:id'
                element={
                    <ProtectedRoute>
                        <EditSubCategory />
                    </ProtectedRoute>
                }
                />
                {/* <Route 
                path='/analytics'
                element={
                    <ProtectedRoute>
                        <AnalyticsAndReporting />
                    </ProtectedRoute>
                }
                /> */}
                <Route 
                path='/coupons'
                element={
                    <ProtectedRoute>
                        <Coupons />
                    </ProtectedRoute>
                }
                />
                <Route 
                path="/coupons/add-new"
                element={
                    <ProtectedRoute>
                        <AddCoupon />
                    </ProtectedRoute>
                }
                />
                <Route 
                path="/coupons/edit/:id"
                element={
                    <ProtectedRoute>
                        <EditCoupon />
                    </ProtectedRoute>
                }
                />
                <Route 
                path="/settings"
                element={
                    <ProtectedRoute>
                        <Settings />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/notifications' 
                element={
                    <ProtectedRoute>
                        <Notifications/>
                    </ProtectedRoute>
                } 
                />
                <Route 
                path='/notifications/send-notification' 
                element={
                    <ProtectedRoute>
                        <SendNotification/>
                    </ProtectedRoute>
                } 
                />
                <Route 
                path='/notifications/edit-notification/:id' 
                element={
                    <ProtectedRoute>
                        <EditNotification/>
                    </ProtectedRoute>
                } 
                />
            </Routes>
        </div>
    );
}

export default App;
