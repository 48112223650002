import BackBtn from 'components/global/BackBtn'
import Heading from 'components/global/Heading'
import Layout from 'components/global/Layout'
import Loader from 'components/global/Loader'
import NameInput from 'components/global/NameInput'
import Axios from 'config/api'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ClipLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import { setLoading, setUpdateLoading } from 'redux/reducers/orderReducer'
import toastError from 'utils/toastError'

const Settings = () => {
    const { user } = useSelector(state => state.auth)
    const [settingData , setSettingData] = useState({
        contactNo : '' ,
        whatsappNo : '' ,
        youtubeLink : '' ,
        twitterLink : '' ,
        facebookLink : '' ,
        instagramLink : '' ,
        deliveryCharges : '' ,
        contactEmail : '',
        shopAddress : ''
    });
    const [loading , setLoading] = useState(false);
    const [updateLoading , setUpdateLoading] = useState(false);
    

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                setLoading(true);
                const { data : { data : { doc } } } = await Axios('/setting');
                setSettingData(prev => ({...prev , ...doc }));
                setLoading(false);
            } catch (error) {
                setLoading(false);
                toastError(error);
            }
        };
        fetchSettings();
    } , [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setUpdateLoading(true);
            const { data : { data : { doc , message } } } = await Axios.post('/setting' , settingData , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
            toast.success(message);
            setSettingData({...doc});
            setUpdateLoading(false);
        } catch (error) {
            setUpdateLoading(false);
            toastError(error);
        }
    }

    return (
        <Layout>
            <div className="flex items-center justify-between gap-4">
                <Heading title='Settings' showIcon={false} />
                <BackBtn />
            </div>
            {
                loading 
                ?
                    <Loader />
                : 
                    <form 
                    className='mt-8 flex flex-col gap-6'
                    onSubmit={handleSubmit}
                    >
                        <div className='shadow-bg p-4 flex flex-col gap-4'>
                            <div className="flex items-center gap-4 sm:flex-row flex-col">
                                <NameInput
                                type='number'
                                label='Contact No'
                                placeholder='Ex : 918934793'
                                name='contactNo'
                                data={settingData}
                                setData={setSettingData}
                                />
                                <NameInput
                                type='number'
                                label='Whatsapp No'
                                placeholder='Ex : 918934793'
                                name='whatsappNo'
                                data={settingData}
                                setData={setSettingData}
                                />
                            </div>
                        </div>
                        <div className='shadow-bg p-4 flex flex-col gap-4'>
                            <div className="flex items-center gap-4 sm:flex-row flex-col">
                                <NameInput
                                label='Youtube Link'
                                placeholder='Ex : youtube.com/rent-and-drive'
                                name='youtubeLink'
                                data={settingData}
                                setData={setSettingData}
                                />
                                <NameInput
                                label='Facebook Link'
                                placeholder='Ex : facebook.com'
                                name='facebookLink'
                                data={settingData}
                                setData={setSettingData}
                                />
                            </div>
                            <div className="flex items-center gap-4 sm:flex-row flex-col">
                                <NameInput
                                label='Twitter Link'
                                placeholder='Ex : twitter.com'
                                name='twitterLink'
                                data={settingData}
                                setData={setSettingData}
                                />
                                <NameInput
                                label='Instagram Link'
                                placeholder='Ex : instagram.com'
                                name='instagramLink'
                                data={settingData}
                                setData={setSettingData}
                                />
                            </div>
                        </div>
                        <div className='shadow-bg p-4 flex flex-col gap-4'>
                            <div className="flex items-center gap-4 sm:flex-row flex-col">
                                <NameInput
                                type='number'
                                label='Delivery Charges'
                                placeholder='Ex : 90'
                                name='deliveryCharges'
                                data={settingData}
                                setData={setSettingData}
                                />
                                <NameInput
                                type='email'
                                label='Email For Contact'
                                placeholder='Ex : exampl@gmail.com'
                                name='contactEmail'
                                data={settingData}
                                setData={setSettingData}
                                />
                            </div>
                            <div className="flex items-center gap-4 sm:flex-row flex-col">
                                <NameInput
                                label='Shop Address'
                                placeholder='Enter your shop or mart address'
                                name='shopAddress'
                                data={settingData}
                                setData={setSettingData}
                                />
                            </div>
                        </div>

                        <div className='mt-4'>
                            <button className="btn-primary py-2 px-12">
                                {
                                    updateLoading
                                    ?
                                        <ClipLoader size={20} color='white' />
                                    :
                                        'Save'
                                }
                            </button>
                        </div>
                    </form>

            }
        </Layout>
    )
}

export default Settings