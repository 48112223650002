import ItemNotFound from 'components/global/ItemNotFound'
import { baseURL } from 'config/api'
import { products } from 'data/products'
import React from 'react'
import shortNumberFormat from 'utils/ShortNumberFormat';
import { DefaultProductImg } from 'utils/default';

const TopSellings = ({ topProducts }) => {
    return (
        <div>
            <div className=' flex items-center justify-between border-b pb-2'>
                <div className='flex items-center gap-2 text-lg text-grayText'>
                    <i className="uil uil-chart text-xl"></i>
                    <h4 className='font-semibold'>Top selling products</h4>
                </div>
                <div className='text-5xl text-grayText'>
                    <i className="uil uil-gift"></i>
                </div>
            </div>
            {
                topProducts?.length > 0 
                ? 
                    <div className='grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-6 py-8'>
                        {
                            topProducts?.map((item , i) => (
                                <div 
                                className='border-2 rounded-lg py-2 px-4 flex flex-col gap-2' 
                                key={item?._id?._id}
                                >
                                    <div className='bg-orange-600 w-fit text-pure rounded-md py-1 px-4 text-[10px]'>
                                        Sold {shortNumberFormat(item?.totalSold)}
                                    </div>
                                    <div className='w-full h-[100px]'>
                                        <img 
                                        src={item?._id?.images?.length > 0 ?baseURL + '/inventories/' + item?._id?.images[0] : DefaultProductImg} 
                                        alt={item?._id?.name} 
                                        className='w-full h-full object-cover'
                                        />
                                    </div>
                                    <div className='text-center text-sm text-grayText'>
                                        {item?._id?.name}
                                    </div>
                                    
                                </div>
                            ))
                        }
                    </div>
                : 
                    <ItemNotFound message='No Top Product Found.' />
            }
        </div>
    )
}

export default TopSellings