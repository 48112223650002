import BackBtn from 'components/global/BackBtn'
import Heading from 'components/global/Heading'
import ItemNotFound from 'components/global/ItemNotFound'
import Layout from 'components/global/Layout'
import Loader from 'components/global/Loader'
import Search from 'components/global/Search'
import Filters from 'components/orderManagement/Filters'
import OrdersMap from 'components/orderManagement/OrdersMap'
import OrdersTable from 'components/orderManagement/OrdersTable'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllOrders } from 'redux/actions/orderActions';
import { setShowOrdersMap } from 'redux/reducers/orderReducer'

const Orders = () => {
    const dispatch = useDispatch();
    
    const { loading , orders , currentPage , status , duration , showOrdersMap  } = useSelector(state => state.order);

    useEffect(() => {
        dispatch(getAllOrders(status))
    }, [currentPage , status , duration])

    return (
        <Layout>
            <div className='pb-12'>
                <div>
                    <BackBtn />
                </div>
                <div className='flex flex-col gap-4 mt-4'>
                    <div className='flex items-center justify-between gap-4 flex-wrap'>
                        <Heading title='All Orders' showIcon={false} />
                        <button 
                        className="btn-primary py-2 px-8"
                        onClick={() => {
                            dispatch(setShowOrdersMap(true));
                        }}
                        >
                            View Orders Map
                        </button>
                    </div>
                    <Filters />
                </div>
                <div className='mt-6'>
                    {
                        loading 
                        ? 
                            <Loader />
                        : 
                        orders?.length > 0 
                        ? 
                            <OrdersTable />
                        : 
                            <ItemNotFound />
                    }
                </div>
            </div>

            { showOrdersMap && <OrdersMap orders={orders} /> }
        </Layout>
    )
}

export default Orders