import Layout from 'components/global/Layout'
import UpdateProfile from 'components/profile/UpdateProfile'
import React, { useState } from 'react'

const Profile = () => {

    return (
        <Layout>
            <div className='pb-12'>
                <UpdateProfile />
            </div>
        </Layout>
    )
}

export default Profile