import Axios from 'config/api';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';
import PhoneInput from './PhoneInput';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [body, setBody] = useState({ phone : '' });
    const [isValid, setIsValid] = useState(false);
    const [loading , setLoading] = useState(false)


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const { data : { data : { message } } } = await Axios.post(`/user/forgot-password` , { phone : body.phone });
            toast.success(message);
            navigate('/verify-forgot-otp');
            localStorage.setItem('phone' , body.phone)
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toastError(error);
        }
    }


    return (
        <div className="fixed top-0 left-0 flex items-center justify-center w-full min-h-screen bg-primary">
            <div className='shadow-bg bg-pure p-4 sm:w-[600px] w-full'>
                <center>
                    <h1 className='text-2xl font-bold'>Forgot Password</h1>
                    <p className='text-sm text-grayText'>You will get an OTP in your Phone</p>
                </center>
                <form 
                className='mt-8'
                onSubmit={handleSubmit}
                >
                    <div className='flex items-center justify-center'>
                        <PhoneInput
                        label='Phone No'
                        name='phone'
                        data={body}
                        setData={setBody}
                        setIsValid={setIsValid}
                        required
                        />
                    </div>
                    <center className='mt-8'>
                        <button 
                        className="btn-primary py-2 sm:px-12 px-6"
                        disabled={loading || !isValid}
                        type='submit'
                        >
                            {
                                loading 
                                ?
                                    <ClipLoader size={20} color='white' />
                                : 
                                    'Send Otp'
                            }
                        </button>
                    </center>
                </form>
                <div className='text-sm text-dark mt-4 text-center flex items-center justify-center gap-1'>
                    <button 
                    className=' underline'
                    onClick={() => navigate('/login')}
                    type='button'
                    >
                        Back to Login
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;