import BackBtn from 'components/global/BackBtn'
import Heading from 'components/global/Heading'
import Layout from 'components/global/Layout'
import Loader from 'components/global/Loader'
import OrderStatus from 'components/global/OrderStatus'
import AssignBookingPopup from 'components/orderManagement/AssignBookingPopup'
import PreBookingsMap from 'components/orderManagement/PreBookingsMap'
import { baseURL } from 'config/api'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { getOrderDetails, updateOrder } from 'redux/actions/orderActions'
import { getVanBookingDetails, updateBooking } from 'redux/actions/vanBookingActions'
import { setShowAssignBookingPopup, setShowBookingsMap } from 'redux/reducers/VanBookingsReducer'

const PreBookingDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [status , setStatus] = useState('');
    const { loading , updateLoading , bookingDetails : item , showAssignBookingPopup , showBookingsMap } = useSelector(state => state.vanBooking) 

    useEffect(() => {
        dispatch(getVanBookingDetails(id));
    }, [id]);

    useEffect(() => {
        if(item) {
            setStatus(item?.status)
        }
    }, [item]);

    const handleChange = (e) => {
        if(window.confirm('Are you sure? You want to update order status? ')) {
            setStatus(e.target.value);
            dispatch(updateBooking(id , { status : e.target.value }));
        }
    }


    return (
        <Layout>
            <div>
                <div className="flex items-center justify-between gap-4 sm:flex-row flex-col">
                    <Heading title='Pre Booking Details' showIcon={false} />
                    <BackBtn />
                </div>
                {
                    loading 
                    ? 
                        <Loader />
                    : 
                        <div className='mt-6 shadow-bg p-4'>
                            <div className='flex items-center justify-between' >
                                <div className='flex items-center gap-12'>
                                    <div className='flex items-center gap-2'>
                                        <h4 className='font-semibold text-dark'>
                                            Order ID :
                                        </h4>
                                        <p className='text-gray-500'>
                                            {item?._id}
                                        </p>
                                    </div>
                                    <div className='flex items-center gap-2'>
                                        <h4 className='font-semibold text-dark'>
                                            Placed On :
                                        </h4>
                                        <p className='text-gray-500'>
                                            {moment(item?.createdAt).format('DD MMM YYYY')}
                                        </p>
                                    </div>
                                    <div>
                                        <OrderStatus status={item?.status} />
                                    </div>
                                </div>
                                <div>
                                    <div className='flex items-center gap-2'>
                                        <h4 className='font-semibold text-dark'>
                                            Status :
                                        </h4>
                                        {
                                            updateLoading
                                            ? 
                                                <ClipLoader size={15} />
                                            :
                                                <select 
                                                className='select-box'
                                                onChange={handleChange}
                                                value={status}
                                                >
                                                    <option value="pending">
                                                        Pending
                                                    </option>
                                                    <option value="processing">
                                                        Processing
                                                    </option>
                                                    <option value="completed">
                                                        Completed
                                                    </option>
                                                    <option value="onTheWay">
                                                        On The Way
                                                    </option>
                                                    <option value="cancelled">
                                                        Cancelled
                                                    </option>
                                                </select>
                                        }
                                    </div>
                                </div>

                            </div>
                            <div className='flex items-center gap-2'>
                                <h4 className='font-semibold text-dark'>
                                    Location :
                                </h4>
                                <p className='text-gray-500'>
                                    test address
                                </p>
                            </div>
                            <div className='flex items-center gap-2'>
                                <h4 className='font-semibold text-dark'>
                                    Booking Amount :
                                </h4>
                                <p className='text-gray-500'>
                                    {item?.bookingAmount}
                                </p>
                            </div>
                            <div className='flex items-center gap-2'>
                                <h4 className='font-semibold text-dark'>
                                    Booking Date :
                                </h4>
                                <p className='text-gray-500'>
                                    {moment(item?.date).format('DD MMM YYYY')}
                                </p>
                            </div>
                            <div className="flex justify-between md:flex-row flex-col gap-4 mt-6">
                                <div className=''>
                                    <Heading title='User Details' showIcon={false}/>
                                    <div className='flex items-center gap-2'>
                                        <h4 className='font-semibold text-dark'>
                                            Full Name :
                                        </h4>
                                        <p className='text-gray-500'>
                                            {item?.user?.firstName + ' ' + item?.user?.lastName}
                                        </p>
                                    </div>
                                    <div className='flex items-center gap-2'>
                                        <h4 className='font-semibold text-dark'>
                                            Email :
                                        </h4>
                                        <p className='text-gray-500'>
                                            {item?.user?.email || '//'}
                                        </p>
                                    </div>
                                    <div className='flex items-center gap-2'>
                                        <h4 className='font-semibold text-dark'>
                                            Phone No :
                                        </h4>
                                        <p className='text-gray-500'>
                                            {item?.user?.phone}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-end items-end gap-4">
                                    <button 
                                    className="btn-secondary h-fit py-2 px-8"
                                    onClick={() => {
                                        dispatch(setShowBookingsMap(true))
                                    }}
                                    >
                                        View On Map
                                    </button>
                                    <button 
                                    className="btn-primary h-fit py-2 px-8"
                                    onClick={() => {
                                        dispatch(setShowAssignBookingPopup(true))
                                    }}
                                    >
                                        Assign Booking
                                    </button>
                                </div>
                            </div>
                            {
                                item?.driver 
                                ?
                                    <div className='mt-8'>
                                        <Heading title='Driver Details' showIcon={false}/>
                                        <div className='flex items-center gap-2'>
                                            <h4 className='font-semibold text-dark'>
                                                Full Name :
                                            </h4>
                                            <p className='text-gray-500'>
                                                {item?.driver?.firstName + ' ' + item?.driver?.lastName}
                                            </p>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <h4 className='font-semibold text-dark'>
                                                Email :
                                            </h4>
                                            <p className='text-gray-500'>
                                                {item?.driver?.email || '//'}
                                            </p>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <h4 className='font-semibold text-dark'>
                                                Phone No :
                                            </h4>
                                            <p className='text-gray-500'>
                                                {item?.driver?.phone}
                                            </p>
                                        </div>
                                    </div>
                                : 
                                    <p className='text-red-500'>Not assigned yet.</p>
                            }
                        </div>

                }
            </div>

            {
                showBookingsMap && item && (
                    <PreBookingsMap 
                    bookings={[item]}
                    />
                )
            }

            {
                showAssignBookingPopup &&
                <AssignBookingPopup 
                booking={item}
                />
            }
        </Layout>
    )
}

export default PreBookingDetails