import PasswordInput from 'components/global/PasswordInput';
import Axios from 'config/api';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';

const ResetPassword = () => {
    const navigate = useNavigate();
    const [_data, setData] = useState({ 
        otp : localStorage.getItem('password-otp') || '' ,
        newPassword : '' , 
        confirmPassword : ''
     });
    const [loading , setLoading] = useState(false)


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const { data : { data : { message } } } = await Axios.post(`/user/reset-password` , _data );
            toast.success(message);
            localStorage.removeItem('password-otp');
            navigate('/login');
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toastError(error);
        }
    }

    return (
        <div className="fixed top-0 left-0 flex items-center justify-center w-full min-h-screen bg-primary">
            <div className='shadow-bg bg-pure p-4 sm:w-[600px] w-full'>
                <center>
                    <h1 className='text-2xl font-bold'>Reset Password</h1>
                </center>
                <form 
                className='mt-8'
                onSubmit={handleSubmit}
                >
                    <div className='flex items-center justify-center flex-col gap-4'>
                        <PasswordInput
                        label='New Password'
                        placeholder='Ex : **************'
                        name='newPassword'
                        data={_data}
                        setData={setData}
                        minLength={8}
                        maxLength={50}
                        required
                        />
                        <PasswordInput
                        label='Confirm New Password'
                        placeholder='Ex : **************'
                        name='confirmPassword'
                        data={_data}
                        setData={setData}
                        minLength={8}
                        maxLength={50}
                        required
                        />
                    </div>
                    <center className='mt-8'>
                        <button 
                        className="btn-primary py-2 sm:px-12 px-6"
                        disabled={loading}
                        type='submit'
                        >
                            {
                                loading 
                                ?
                                    <ClipLoader size={20} color='white' />
                                : 
                                    'Update'
                            }
                        </button>
                    </center>
                </form>
                <div className='text-sm text-dark mt-4 text-center flex items-center justify-center gap-1'>
                    <button 
                    className=' underline'
                    onClick={() => navigate('/login')}
                    type='button'
                    >
                        Back to Login
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;