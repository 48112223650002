import axios from 'axios';
import ItemNotFound from 'components/global/ItemNotFound';
import Loader from 'components/global/Loader';
import Axios, { baseURL } from 'config/api';
import defaultImg from 'data/defaultImg';
import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { setShowAssignBookingPopup, updateDoc } from 'redux/reducers/VanBookingsReducer';
import toastError from 'utils/toastError';

const AssignBookingPopup = ({ booking  }) => {
    const dispatch = useDispatch();
    const [drivers , setDrivers] = useState([]);
    const [loading , setLoading] = useState(false);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [assignLoading , setAssignLoading] = useState(false);

    const { user } = useSelector(state => state.auth);

    useEffect(() => {
        const fetchDrivers = async () => {
            try {
                setLoading(true);
                const { data : { data : { docs } } } = await Axios('/user/total-drivers' , {
                    headers : {
                        Authorization : `Bearer ${user?.token}`
                    }
                });
                setDrivers(docs);
                setLoading(false); 
            } catch (error) {
                setLoading(false);
                toastError(error);
            }
        }
        fetchDrivers();
    }, []);

    const assignBookingHandler = async () => {
        try {
            setAssignLoading(true);
            const { data : { data : { message , doc } } } = await Axios.put(`/van-booking/assign/${booking?._id}` , {
                driverId : selectedDriver?._id
            } , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
            toast.success(message);
            dispatch(setShowAssignBookingPopup(false));
            dispatch(updateDoc(doc));
            setAssignLoading(false);
        } catch (error) {
            setAssignLoading(false);
            toastError(error);
        }
    }


    return (
        <div className='absolute top-0 left-0 w-full h-screen flex items-center justify-center bg-black bg-opacity-50 p-4 z-50'>
            <div className='bg-white relative max-h-[450px] overflow-auto p-4 rounded-lg sm:w-[600px] w-full'>
                <div 
                className='absolute top-1 right-2 cursor-pointer text-2xl'
                onClick={() => dispatch(setShowAssignBookingPopup(false))}
                >
                    <i className="uil uil-times"></i>
                </div>
                <center>
                    <h3 className='text-2xl font-semibold text-secondary'>Assign Booking</h3>
                </center>
                {
                    loading 
                    ? 
                        <div>
                            <Loader />
                        </div>
                    : 
                    drivers?.length > 0 
                    ? 
                        <div>
                           
                            <ul className='flex flex-col gap-4 mt-6'>
                                { 
                                drivers?.map(item => {
                                    return <li
                                    key={item?._id} 
                                    className={`flex items-center gap-4 bg-gray-100   rounded-lg p-2
                                    ${selectedDriver?._id === item?._id ? ' border-2 border-secondary hover:border-secondary' : ' border hover:border-primary border-transparent'}
                                    `}
                                    onClick={() => setSelectedDriver(item)}
                                    >
                                    <div>
                                        <img 
                                        src={item?.image ? baseURL + '/drivers/' + item?.image : defaultImg} 
                                        alt={item?.firstName}
                                        className='w-[50px] h-[50px] rounded-full object-cover' 
                                        />
                                    </div>
                                    <div className='flex flex-col'>
                                        <span className='font-medium'>
                                            {item?.firstName + ' ' + item?.lastName}
                                        </span>
                                        <span className='text-xs text-gray-500'>
                                            {item?.email}
                                        </span>
                                    </div>
                                </li>
                                })
                                } 
                            </ul>
                            <div className='mt-4 flex justify-end'>
                                <button 
                                className="btn-primary py-2 px-8"
                                onClick={assignBookingHandler}
                                disabled={assignLoading}
                                >
                                    {
                                        assignLoading
                                        ? 
                                            <ClipLoader size={20} color='white' />
                                        : 
                                            'Done'
                                    }
                                </button>
                            </div>
                        </div>
                    : 
                        <ItemNotFound message='No driver found.' />
                }
            </div>
        </div>
    )
}

export default memo(AssignBookingPopup)