import BackBtn from 'components/global/BackBtn'
import Heading from 'components/global/Heading'
import Layout from 'components/global/Layout'
import Axios from 'config/api'
import React, {   useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { read, utils } from "xlsx";

const requiredFields = ["Item Name" , "Current Stock" , "GST Tax" , "Item Code" , "Sales Price" , "MRP" , "Purchase Price" , "Item ID"];

const BulkUpload = () => {
    const navigate= useNavigate();
    const fileRef = useRef();
    const { user } =  useSelector(state => state.auth);
    const [loading, setLoading] = useState(false);
    const [excelRows, setExcelRows] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);

    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const file = e.target.files[0];
            setSelectedFile(file);
            const reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = read(data, { type: "binary" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = utils.sheet_to_json(worksheet);
                setExcelRows(json);
            };
        }
    };

    const uploadData = async () => {
        if(window.confirm('Are you sure? You want to upload this data?')){
            try {
                setLoading(true);
                const inventories = excelRows.map((obj) => ({
                    // itemId : obj["Item ID"] ,
                    name: obj["Item Name"] || "",
                    mainCategory: obj["Main Category"] || "",
                    subCategory: obj["Sub Category"] || "",
                    stock: obj["Current Stock"] || 0,
                    salesPrice: obj["Sales Price"] || 0,
                    itemCode : obj["Item Code"] || 0,
                    MRP : obj["MRP"] || 0,
                    purchasePrice : obj["Purchase Price"] ,
                    description : obj["Description"] || 0,
                }));
    
                const { data : { data : { message } } } = await Axios.post('/inventory/bulk-upload' , inventories , {
                    headers : {
                        Authorization : `Bearer ${user?.token}`
                    }
                });
                navigate('/inventory-management/inventories');
                toast.success(message);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log("uploadData error: ", error);
            }
        }
    };

    const removeFile = () => {
        setSelectedFile(null);
        setExcelRows([]);
        window.location.reload();
    };

    return (
        <Layout>
            <div>
                <div className='flex items-center justify-between gap-4'>
                    <div>
                        <Heading title='Add Bulk Inventory' />
                    </div>
                    <div>
                        <BackBtn />
                    </div>
                </div>
                <div 
                className="flex items-center justify-center border rounded-md h-[300px] flex-col gap-1 bg-gray-100 hover:bg-gray-200 cursor-pointer mt-4"
                onClick={() => fileRef.current.click()}
                >
                    <div>
                        <svg className="w-20 h-12 mb-4 text-gray-500 dark:text-gray-400" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                        </svg>
                    </div>
                    <h5 className='text-lg font-medium text-gray-600'>Click to upload Excel File</h5>
                    {
                        selectedFile?.name && 
                        <h5>{selectedFile?.name}</h5>
                    }
                    <input
                        ref={fileRef}
                        name="file"
                        type="file"
                        onChange={readUploadFile}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        hidden
                    />
                </div>
                <div className='flex items-center gap-6 mt-8 justify-end'>
                    {selectedFile?.name && (
                        <button 
                        disabled={loading} 
                        onClick={uploadData}
                        className='btn-primary py-2 px-8'
                        >
                            {
                                loading 
                                ? 
                                    <ClipLoader size={20} color='white' /> 
                                : 
                                    "Upload data"
                            }
                        </button>
                    )}{" "}
                    {selectedFile?.name && (
                        <button 
                        disabled={loading} 
                        onClick={removeFile}
                        className='btn-secondary py-2 px-4'
                        >
                            {"Remove file"}
                        </button>
                    )}
                </div>

                
            </div>
        </Layout>
    )
}

export default BulkUpload