import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import './utilities.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import DrawerContextProvider from 'context/DrawerContext';

import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { Provider } from 'react-redux';
import store from 'redux/store';

import { QueryClient , QueryClientProvider } from 'react-query';
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
        <QueryClientProvider client={queryClient}>
        <Provider store={store} >
            <DrawerContextProvider>
                <App />
            </DrawerContextProvider>
        </Provider>
        </QueryClientProvider>
    </Router>
);

