import { useDispatch, useSelector } from 'react-redux';
import { setDuration, setStatus } from 'redux/reducers/VanBookingsReducer'


const orderStatuses = ['pending' , 'confirmed' , 'onTheWay' , 'cancelled' , 'completed', 'declined'];
const durationFilters = ['today' , 'yesterday' , 'week' , 'month' , 'year'];

const PreBookingFilters = () => {
    const dispatch = useDispatch();
    const { status , duration } = useSelector(state => state.vanBooking);


    return (
        <div className="shadow-bg p-4">
            <div className='flex items-center gap-4'>
                <div className="flex-1 flex flex-col gap-1.5">
                    <label className='label'>
                        Duration
                    </label>
                    <select 
                    className='py-3 px-2 border border-secondary rounded-md '
                    onChange={e => dispatch(setDuration(e.target.value))}
                    value={duration}
                    >   
                        <option value={''}>All</option>
                        {
                            durationFilters.map(item => (
                                <option 
                                key={item}
                                className='capitalize' 
                                value={item}
                                >
                                    {item}
                                </option>
                            ))
                        }
                    </select>
                </div>
                <div className="flex-1 flex flex-col gap-1.5">
                    <label className='label'>
                        Status
                    </label>
                    <select 
                    className='py-3 px-2 border border-secondary rounded-md'
                    onChange={e => dispatch(setStatus(e.target.value))}
                    value={status}
                    >   
                        <option value={''}>All</option>
                        {
                            orderStatuses.map(item => (
                                <option 
                                key={item}
                                className='capitalize' 
                                value={item}
                                >
                                    {item}
                                </option>
                            ))
                        }
                    </select>
                </div>
            </div>
        </div>
    )
}

export default PreBookingFilters;